<script setup lang="ts">
import { ref } from "vue";
import { vIntersectionObserver } from "@vueuse/components";
import VInlineButton from "@magnit/core/src/components/VInlineButton/VInlineButton.vue";
import VHidden from "@magnit/core/src/components/VHidden/VHidden.vue";
import VWrapper from "@magnit/core/src/components/VWrapper/VWrapper.vue";
import { Swiper as SwiperClass } from "swiper";
import VSlider from "~/components/VSlider/VSlider.vue";
import VSlide from "~/components/VSlider/VSlide.vue";
import VTitle from "~/components/VTitle.vue";
import VWrapperLocal from "~/components/VWrapperLocal.vue";
import ArrowIcon from "~/assets/svg/icons/arrow.svg";
import { Routes } from "~/utils/routes";
import { useContentStore } from "~/store/content";
import { useStoresStore } from "~/store/stores";

const contentStore = useContentStore();
const storesStore = useStoresStore();

const onMoreClick = () => {
  send("MainPage:Exclusive:Forward:Click");
};

// Analytics
const { send } = useAnalytics();
const isVisible = ref(false);
const onVisibility = ([{ isIntersecting }]: IntersectionObserverEntry[]) => {
  isVisible.value = isIntersecting;
  if (isIntersecting) {
    send("MainPage:Exclusive:View");
  }
};
const onSlideClick = (swiper: SwiperClass) => {
  send("MainPage:Exclusive:Item:Click", {
    position: swiper.clickedIndex + 1,
    title: contentStore.mainPage?.exclusiveWithUs[swiper.clickedIndex].title,
    dest: contentStore.mainPage?.exclusiveWithUs[swiper.clickedIndex].offerUrl,
  });
};
const onSlideScroll = (swiper: SwiperClass) => {
  send("MainPage:Exclusive:Scroll", {
    scroll_line: swiper.realIndex > swiper.previousIndex ? "right" : "left",
  });
};

const root = ref(null);

function onVisibilityItem(
  index: number,
  [{ isIntersecting }]: IntersectionObserverEntry[],
) {
  if (isIntersecting) {
    send("MainPage:Exclusive:Item:View", {
      position: index + 1,
      title: contentStore.mainPage?.exclusiveWithUs[index].title,
      dest: contentStore.mainPage?.exclusiveWithUs[index].offerUrl,
    });
  }
}
</script>

<template>
  <div
    v-if="contentStore.mainPage?.exclusiveWithUs?.length"
    ref="root"
    v-intersection-observer="onVisibility"
    class="private-labels"
  >
    <VWrapperLocal class="private-labels__wrapper">
      <VTitle color="invert-primary">
        Эксклюзивно у нас

        <template #aside>
          <NuxtLink
            :to="`${Routes.Catalog}?shopCode=${storesStore.requestShopCode}`"
          >
            <div class="private-labels__more">
              <VHidden :hide="{ xs: false, ml: true }">
                <VInlineButton theme="invert" @click="onMoreClick">
                  <template #icon>
                    <ArrowIcon />
                  </template>
                </VInlineButton>
              </VHidden>

              <VHidden :hide="{ xs: true, ml: false }">
                <VInlineButton
                  theme="invert"
                  icon-position="right"
                  @click="onMoreClick"
                >
                  Посмотреть все
                  <template #icon>
                    <ArrowIcon />
                  </template>
                </VInlineButton>
              </VHidden>
            </div>
          </NuxtLink>
        </template>
      </VTitle>
    </VWrapperLocal>

    <VWrapper class="private-labels__slider-wrapper">
      <VSlider
        :breakpoints="{
          xs: {
            slidesPerView: 'auto',
            spaceBetween: 8,
            slidesOffsetAfter: 12,
            slidesOffsetBefore: 12,
          },
          s: {
            slidesPerView: 'auto',
            spaceBetween: 12,
            slidesOffsetAfter: 20,
            slidesOffsetBefore: 20,
          },
          m: {
            slidesPerView: 'auto',
            spaceBetween: 20,
            slidesOffsetAfter: 32,
            slidesOffsetBefore: 32,
          },
          ml: {
            slidesPerView: 'auto',
            spaceBetween: 20,
            slidesOffsetAfter: 32,
            slidesOffsetBefore: 32,
          },
          l: {
            slidesPerView: 4,
            spaceBetween: 24,
            slidesOffsetAfter: 0,
            slidesOffsetBefore: 0,
            navigation: true,
          },
        }"
        @slide-click="onSlideClick"
        @slide-change-by-user="onSlideScroll"
        @navigation-next="onSlideScroll"
        @navigation-prev="onSlideScroll"
      >
        <VSlide
          v-for="(item, index) in contentStore.mainPage?.exclusiveWithUs"
          :key="index"
          :size="{ xs: 7, m: 5, ml: 4, l: 'auto' }"
        >
          <NuxtLink
            v-intersection-observer="[
              (io) => onVisibilityItem(index, io),
              { root },
            ]"
            class="private-labels__item"
            :to="item.offerUrl"
          >
            <NuxtImg
              :src="item.publicImageUrl"
              :alt="item.title"
              loading="lazy"
            />
            <VDisclaimer :content="item.disclaimer" />
          </NuxtLink>
        </VSlide>
      </VSlider>
    </VWrapper>
  </div>
</template>

<style lang="postcss">
.private-labels {
  margin-top: 64px;
  padding-bottom: 48px;
  padding-top: 1px;
  background-color: #a30051;

  &__wrapper {
    padding-top: 1px;
  }

  &__more {
    display: flex;
    align-items: center;
  }

  &__item {
    position: relative;
    display: flex;
    border-radius: 20px;
    overflow: hidden;

    & .disclaimer {
      right: var(--pl-unit-x5);
      bottom: var(--pl-unit-x2);
    }

    & img {
      cursor: pointer;
      width: 100%;
    }

    &:hover {
      border-radius: 0;

      &::before {
        cursor: pointer;
        position: absolute;
        content: "";
        inset: 0;
        border: 16px solid #f1e9dd;
      }
    }
  }
}

@media (--pl-viewport-from-m) {
  .private-labels__item {
    border-radius: 20px;
  }
}

@media (--pl-viewport-from-l) {
  .private-labels__item {
    border-radius: 24px;
  }

  .private-labels .private-labels__slider-wrapper {
    padding: 0 32px;
  }
}

@media (--pl-viewport-from-xl) {
  .private-labels__item {
    border-radius: 32px;

    & .disclaimer {
      right: var(--pl-unit-x6);
    }
  }
}
</style>

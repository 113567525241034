<script setup lang="ts">
import { ref } from "vue";
import { vIntersectionObserver } from "@vueuse/components";
import { useResizeObserver } from "@vueuse/core";
import VInlineButton from "@magnit/core/src/components/VInlineButton/VInlineButton.vue";
import VHidden from "@magnit/core/src/components/VHidden/VHidden.vue";
import VWrapper from "@magnit/core/src/components/VWrapper/VWrapper.vue";
import type { IProduct } from "@magnit/unit-catalog/src/unit-catalog.types";
import VProductPreview from "@magnit/unit-catalog/src/components/VProductPreview.vue";
import { Swiper as SwiperClass } from "swiper";
import VSlider from "~/components/VSlider/VSlider.vue";
import VSlide from "~/components/VSlider/VSlide.vue";
import VTitle from "~/components/VTitle.vue";
import VWrapperLocal from "~/components/VWrapperLocal.vue";
import ArrowIcon from "~/assets/svg/icons/arrow.svg";
import { Routes } from "~/utils/routes";
import { useStoresStore } from "~/store/stores";

const { requestPromotionsByType } = promoApi();
const { requestShopId } = storeToRefs(useStoresStore());
const { data } = await requestPromotionsByType({
  type: [1, 2],
  storeId: requestShopId.value,
  limit: 12,
  offset: 0,
});
const promoProducts = computed(() => data.value?.goods || []);

const onMoreClick = () => {
  send("MainPage:Popular:Forward:Click");
};

const { send } = useAnalytics();
const sliderRef = ref<HTMLDivElement>();
const isVisible = ref(false);

useResizeObserver(sliderRef, () => {
  if (sliderRef.value) {
    const imageElement =
      sliderRef.value?.querySelector<HTMLDivElement>(".product-gallery");
    const navigationElements =
      sliderRef.value?.querySelectorAll<HTMLDivElement>(".slider-navigation");
    if (imageElement && navigationElements.length) {
      const imageHeight = imageElement.clientHeight;
      navigationElements.forEach((arrowNode) => {
        arrowNode.style.top = `${imageHeight / 2}px`;
      });
    }
  }
});

const onVisibility = ([{ isIntersecting }]: IntersectionObserverEntry[]) => {
  isVisible.value = isIntersecting;
  if (isIntersecting) {
    send("MainPage:Popular:View");
  }
};

const onItemClick = (item: IProduct, index: number) => {
  send("MainPage:Popular:Item:Click", {
    position: index + 1,
    item_id: item.id,
    item_name: item.title,
    dest: item.link,
  });
};

const onSlideScroll = (swiper: SwiperClass) => {
  send("MainPage:Popular:Scroll", {
    scroll_line: swiper.realIndex > swiper.previousIndex ? "right" : "left",
  });
};

const root = ref(null);

function onVisibilityItem(
  item: IProduct,
  index: number,
  [{ isIntersecting }]: IntersectionObserverEntry[],
) {
  if (isIntersecting) {
    send("MainPage:Popular:Item:View", {
      position: index + 1,
      item_id: item.id,
      item_name: item.title,
      dest: item.link,
    });
  }
}
</script>

<template>
  <div v-if="promoProducts.length" class="popular">
    <VWrapperLocal ref="root" v-intersection-observer="onVisibility">
      <VTitle>
        Товары недели

        <template #aside>
          <NuxtLink :to="Routes.PromoCatalog">
            <div class="popular__more">
              <VHidden :hide="{ xs: false, ml: true }">
                <VInlineButton @click="onMoreClick">
                  <template #icon>
                    <ArrowIcon />
                  </template>
                </VInlineButton>
              </VHidden>

              <VHidden :hide="{ xs: true, ml: false }">
                <VInlineButton icon-position="right" @click="onMoreClick">
                  Посмотреть все
                  <template #icon>
                    <ArrowIcon />
                  </template>
                </VInlineButton>
              </VHidden>
            </div>
          </NuxtLink>
        </template>
      </VTitle>
    </VWrapperLocal>

    <VWrapper class="popular__slider-wrapper">
      <div ref="sliderRef" class="popular__slider">
        <VSlider
          :breakpoints="{
            xs: {
              slidesPerView: 'auto',
              spaceBetween: 8,
              slidesOffsetAfter: 12,
              slidesOffsetBefore: 12,
            },
            s: {
              slidesPerView: 'auto',
              spaceBetween: 12,
              slidesOffsetAfter: 20,
              slidesOffsetBefore: 20,
            },
            m: {
              slidesPerView: 'auto',
              spaceBetween: 20,
              slidesOffsetAfter: 32,
              slidesOffsetBefore: 32,
            },
            ml: {
              slidesPerView: 'auto',
              spaceBetween: 20,
              slidesOffsetAfter: 32,
              slidesOffsetBefore: 32,
            },
            l: {
              slidesPerView: 4,
              spaceBetween: 24,
              slidesOffsetAfter: 0,
              slidesOffsetBefore: 0,
              navigation: true,
            },
            xl: {
              slidesPerView: 6,
              spaceBetween: 24,
              slidesOffsetAfter: 0,
              slidesOffsetBefore: 0,
            },
          }"
          @slide-change-by-user="onSlideScroll"
          @navigation-next="onSlideScroll"
          @navigation-prev="onSlideScroll"
        >
          <VSlide
            v-for="(item, index) in promoProducts"
            :key="item.id"
            :size="{ xs: 6, m: 4, ml: 3, l: 'auto' }"
            class="popular__item"
          >
            <VProductPreview
              v-intersection-observer="[
                (io) => onVisibilityItem(item, index, io),
                { root },
              ]"
              v-bind="item"
              @click="onItemClick(item, index)"
            />
          </VSlide>
        </VSlider>
      </div>
    </VWrapper>
  </div>
</template>

<style lang="postcss" scoped>
.popular {
  &__slider-wrapper {
    @media (--pl-viewport-from-l) {
      padding: 0 32px;
    }
  }

  &__more {
    display: flex;
    align-items: center;
  }

  &__item {
    height: auto;
    cursor: pointer;
  }
}
</style>

import { ref, watch, computed } from "vue";
import type { IProduct } from "@magnit/unit-catalog/src/unit-catalog.types";
import type { ICategorySectionResponse } from "~/store/content";
import { ProductCatalogType, useContentStore } from "~/store/content";
import useProductsListWithYaSlot from "~/composables/useProductsListWithYaSlot";
import { Routes } from "~/utils/routes";
import { useCategoriesStore } from "~/store/categories";
import { useStoresStore } from "~/store/stores";
import { CatalogBFFSortV2Order, CatalogBFFSortV2Type } from "~/typings/api/goods";

const useMainPageCategoryProducts = (
  categorySection: ICategorySectionResponse,
) => {
  const contentStore = useContentStore();
  const { requestPromotions } = promoApi();
  const { requestProducts: requestGoods } = goodsApi();
  const categoriesStore = useCategoriesStore();
  const storesStore = useStoresStore();
  const products = ref<IProduct[]>([]);

  async function requestProducts() {
    if (contentStore.status.mainPage !== "success") {
      return;
    }

    const productsPromise = categorySection.categoryType === ProductCatalogType.Promo
      ? requestPromotions({
        storeId: storesStore.requestShopId,
        categoryId: categorySection.categoryId,
        adult: true,
        limit: 8,
        offset: 0,
      })
      : requestGoods({
        categories: categorySection.categoryId ? [categorySection.categoryId] : [],
        pagination: { offset: 0, limit: 8 },
        sort: {
          order: CatalogBFFSortV2Order.desc,
          type: CatalogBFFSortV2Type.popularity,
        },
      });

    const { data } = await productsPromise;
    if (Array.isArray(data.value?.goods)) {
      products.value = data.value.goods;
    }
  }

  watch(
    () => contentStore.status.mainPage,
    async (next) => {
      if (next === "success") {
        await requestProducts();
      }
    },
    {
      immediate: true,
    },
  );

  const productsWithYaSlot = categorySection.adfoxPayload
    ? useProductsListWithYaSlot(
      computed(() => products.value),
      categorySection.adfoxPayload[0],
      APP_MAIN_PAGE_CATEGORY_BANNER_INDEX,
      undefined,
      true,
    )
    : computed(() => products.value);

  const onStabAdfox = (index: number) => {
    products.value?.splice(index, 1);
  };

  const link = computed(() => {
    if (!categorySection) {
      return "";
    }

    const category = categorySection.categoryId
      ? categoriesStore.getCategoryById(
        categorySection.categoryId.toString(),
        categorySection.categoryType === ProductCatalogType.Promo
          ? "promo"
          : "goods",
      )
      : null;

    let url = `${
      categorySection.categoryType === ProductCatalogType.Promo
        ? Routes.PromoCatalog
        : Routes.Catalog
    }`;
    if (category) {
      url += `/${categorySection.categoryId}-${category.code}`;
    }
    url += `?shopCode=${storesStore.requestShopCode}`;
    return url;
  });

  return {
    link,
    productsWithYaSlot,
    onStabAdfox,
  };
};

export default useMainPageCategoryProducts;
